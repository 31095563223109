import React, { useState, useRef, useEffect } from 'react';
import {
    Box,
    Text,
    Flex,
    useColorModeValue,
    Textarea,
    Link,
    Tooltip,
    useToast,
    Button,
    WrapItem,
    Select,
    Modal,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    IconButton,
    ModalOverlay,
    useDisclosure
} from '@chakra-ui/react';
import { useSelector } from "react-redux";
import { RootState, AppDispatch } from "../../redux/store";
import { useNavigate } from "react-router-dom";
import { FaEraser, FaSave } from "react-icons/fa"
import { useDispatch } from "react-redux";
// import { setModeDisable } from '../../redux/modeDisableSlice';
import { v4 as uuidv4 } from 'uuid';
import { PhoneIcon, AddIcon, WarningIcon, DeleteIcon, ChevronRightIcon } from '@chakra-ui/icons'
import { BiSend } from 'react-icons/bi';
import { AIChatHistoryInterface, AIChatRoomInterface, AudioModelInterface, GPTModelInterface, PlanInterface } from '../../utils/interfaces';
import { getCookie, handleDeleteRequest, handleGetRequest, handlePostRequest } from '../../utils/helpers';
import { setChatHistorys, setChatRooms } from '../../redux/appSlice';

const letterDelayTime = 20

export default function Home() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const toast = useToast();
    const textAreaRef = useRef<HTMLTextAreaElement>();
    const userChatBgColor = useColorModeValue("gray.500", "gray.500");
    const systemChatBgColor = useColorModeValue("gray.100", "gray.700");
    // const [loading, setLoading] = useState('メッセージを入力してください');
    const [loading, setLoading] = useState<boolean>(false);
    const [eraserOpacity, setEraserOpacity] = useState<string>("20%");
    const [saveOpacity, setSaveOpacity] = useState<string>("20%");
    const [content, setContent] = useState<string>("");
    const [chatRoom, setChatRoom] = useState<AIChatRoomInterface | null>();

    const me = useSelector((state: RootState) => state.me);
    const plans = useSelector((state: RootState) => state.app.plans)
    const gptModel = useSelector((state: RootState) => state.app.gptModel);
    const audioModel = useSelector((state: RootState) => state.app.audioModel)
    const chatRooms = useSelector((state: RootState) => state.app.chatRooms);
    const chatHistorys = useSelector((state: RootState) => state.app.chatHistorys);
    const scrollRef = useRef<HTMLDivElement | null>(null);
    const [lastChat, setLastChat] = useState<AIChatHistoryInterface>();
    const [lastContent, setLastContent] = useState<string>("");

    const [curChatHistory, setCurChatHistory] = useState<AIChatHistoryInterface[]>([]);
    const OverlayOne = () => (
        <ModalOverlay
            bg='blackAlpha.300'
            backdropFilter='blur(10px) hue-rotate(90deg)'
        />
    )

    const { isOpen, onOpen, onClose } = useDisclosure()

    // useEffect(() => {
    //     let chatArea = document.getElementById('chatArea');
    //     chatArea?.scrollBy(0, chatArea?.scrollHeight)
    // }, [userChatList, systemChatList])


    async function delay(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    function newChat() {
        setChatRoom(null)
        setCurChatHistory([])
    }

    function saveChat() {
    }

    const sendMessage = async () => {
        if(!me.me) navigate('/plan')
        let firstChat = false;
        if (curChatHistory.length == 0) {
            firstChat = true;
        }
        setLoading(true)
        setLastContent(content)
        setContent("")
        try {
            const resp = await handlePostRequest(
                `${process.env.REACT_APP_BACKEND_URL}/api/ai-chat/aichat/`,
                {
                    firstChat: firstChat,
                    gptModelId: gptModel?.id,
                    audioModelId: audioModel?.id,
                    roomId: chatRoom?.id,
                    content: content,
                },
                toast,
                'default',
                navigate
            )
            if (chatRooms.filter((item) => { return item.id == resp.data.chat_room.id }).length == 0) {
                const prevChatRooms = [...chatRooms]
                prevChatRooms.unshift(resp.data.chat_room)
                dispatch(setChatRooms(prevChatRooms))
                setChatRoom(resp.data.chat_room)
            }
            const newChatHistory = { ...resp.data.chat_history }
            newChatHistory.ai_response = ""
            setCurChatHistory((prev) => [...prev, newChatHistory])
            dispatch(setChatHistorys([...chatHistorys, resp.data.chat_history]))
            setLastChat(resp.data.chat_history)
        } catch (error) {
        } finally {
            setLoading(false)
            setLastContent("")
        }
    }

    function handleEnter(e: any) {
        if (!e.shiftKey && e.key == "Enter") {
            e.preventDefault();
            sendMessage()
        }
    }

    const removeChat = async () => {
        const resp = await handleDeleteRequest(
            `${process.env.REACT_APP_BACKEND_URL}/api/ai-chat/ai-chat-rooms/${chatRoom?.id}/`,
            toast,
            "default"
        )
        if (resp) {
            newChat()
            const prevChatRooms = [...chatRooms]
            const indexToDelete = chatRooms.findIndex((item) => { return item.id == chatRoom?.id })
            prevChatRooms.splice(indexToDelete, 1)
            dispatch(setChatRooms(prevChatRooms))
            onClose()
        }
    }

    function removeChatModal() {
        onOpen()
    }

    useEffect(() => {
        if (scrollRef.current) {
            scrollRef.current.scrollIntoView({ block: 'end' });
            // scrollRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }
        if (textAreaRef.current) {
            textAreaRef.current.focus();
        }
    }, [curChatHistory])

    useEffect(() => {
        if (lastChat) {
            for (let i = 0; i < lastChat.ai_response.length; i++) {
                delay(letterDelayTime * i).then(() => {
                    const prevChatHistory = [...curChatHistory]
                    prevChatHistory[prevChatHistory.length - 1].ai_response += lastChat.ai_response[i]
                    setCurChatHistory(prevChatHistory)
                })
            }
        }
    }, [lastChat])

    return (
        <>
            {/* Title Area */}
            {plans.find((item) => { return item.id == me.me?.plan_id })?.plan_name == "pro" &&
                <>
                    <Box
                        pos={'absolute'}
                        w={240}
                        m={3}
                        mr={1}
                        right={0}
                        top={70}
                        display={{ base: "none", md: "block" }}
                        border='1px'
                        borderColor={userChatBgColor}
                        bottom={0}
                        overflowY={'auto'}
                        overflowX={'hidden'}
                        css={{
                            '&::-webkit-scrollbar': {
                                width: '4px',
                            },
                            '&::-webkit-scrollbar-track': {
                                width: '6px',
                            },
                            '&::-webkit-scrollbar-thumb': {
                                background: "#56c9c7",
                                borderRadius: '24px',
                            },
                        }}
                    >
                        <WrapItem>
                            <Tooltip label="新しい" placement='left'>
                                <Button colorScheme='gray' size={"sm"} w={"100%"} display={'block'} m={1} textAlign={'left'} onClick={() => newChat()}>新しい</Button>
                            </Tooltip>
                        </WrapItem>
                        {
                            chatRooms.map((item, idx) => {
                                return (
                                    <WrapItem key={idx}>
                                        <Tooltip label={item.title} placement='left'>
                                            <Button
                                                rightIcon={
                                                    <DeleteIcon _hover={{ color: "red.500", cursor: "pointer" }} onClick={() => removeChatModal()} />} justifyContent={'space-between'} colorScheme={(chatRoom?.id == item.id ? 'blackAlpha' : 'gray')} size={"sm"} w={"100%"} ml={'auto'} display={'flex'} m={1} textAlign={'left'} onClick={() => { setChatRoom(item); setCurChatHistory(chatHistorys.filter((chat) => chat.ai_chat_room_id == item.id)) }}>
                                                {(item.title.length > 12) ?
                                                    item.title.slice(0, 12) + "..." :
                                                    item.title
                                                }
                                            </Button>
                                        </Tooltip>
                                    </WrapItem>
                                )
                            })
                        }
                    </Box>
                    <Menu>
                        <MenuButton
                            as={Button}
                            zIndex={990}
                            width={"100%"}
                            size={"sm"}
                            display={{ base: "block", md: "none" }}
                        >
                            チャットを選択
                        </MenuButton>
                        <MenuList width={"100%"}>
                            <MenuItem
                                width={"100%"}
                                onClick={() => newChat()}
                            >新しい</MenuItem>
                            {chatRooms.map((item, idx) => {
                                return (
                                    <MenuItem
                                        width={"100%"}
                                        key={idx}
                                        onClick={() => { setChatRoom(item); setCurChatHistory(chatHistorys.filter((chat) => chat.ai_chat_room_id == item.id)) }}
                                    >
                                        {item.title}
                                        <DeleteIcon
                                            color="gray.500"
                                            ml="auto"
                                            mr={2}
                                            _hover={{ color: "red.500", cursor: "pointer" }}
                                            onClick={() => removeChatModal()}
                                        />
                                    </MenuItem>
                                )
                            })}
                        </MenuList>
                    </Menu>
                </>
            }
            {/* Description Area */}
            <Box display={(curChatHistory.length != 0 ? 'none' : 'block')} mt={250} pos={'relative'} mr={{ base: 0, md: plans.find((item) => { return item.id == me.me?.plan_id })?.plan_name == 'pro' ? 250 : 2 }}>
                <Flex fontSize={{ base: 16, md: 24 }} align={'center'} justify={'center'}>
                    <Text>~音声学習 for ChatGPT~</Text>
                </Flex>
                <Flex justify={'center'}>
                    <Text
                        fontSize={{ base: 20, md: 32 }}
                        align={'center'}
                    >
                        耳で学ぼう、音声チャット、音声学習ができる
                    </Text>
                </Flex>
            </Box>

            {/* Chat Area */}
            <Box
                alignItems={'center'}
                pos={'fixed'}
                // height={"50%"}
                // width={"80%"}
                left={{ base: 3, md: 250 }}
                top={{ base: 150, md: 100 }}
                right={{ base: 3, md: plans.find((item) => { return item.id == me.me?.plan_id })?.plan_name == 'pro' ? 250 : 2 }}
                bottom={100}
                overflowY={"auto"}
                id='chatArea'
                css={{
                    '&::-webkit-scrollbar': {
                        width: '4px',
                    },
                    '&::-webkit-scrollbar-track': {
                        width: '6px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        background: "#56c9c7",
                        borderRadius: '24px',
                    },
                }}
            >
                {curChatHistory.map((item, i) => {
                    // if (chat.role == 'user'){
                    return (
                        <Box key={i}>
                            <Flex justify={'right'}>
                                <Text
                                    p={2}
                                    m={1}
                                    borderRadius={5}
                                    justifySelf={'end'}
                                    bg={userChatBgColor}
                                    whiteSpace={'pre-wrap'}
                                    wordBreak={'break-all'}
                                >
                                    {item.content}
                                </Text>
                            </Flex>
                            <Flex justify={'left'}>
                                <Text
                                    p={2}
                                    m={1}
                                    borderRadius={5}
                                    justifySelf={'end'}
                                    bg={systemChatBgColor}
                                    whiteSpace={'pre-wrap'}
                                    wordBreak={'break-all'}
                                >
                                    {item.ai_response}
                                </Text>
                            </Flex>
                            {
                                curChatHistory.length - 1 == i ?
                                    <Flex justify={'left'} ref={scrollRef}>
                                        <audio controls style={{ height: '35px' }}>
                                            <source src={`${item.audio_path}`} type="audio/mp3" />
                                        </audio>
                                    </Flex> :
                                    <Flex justify={'left'}>
                                        <audio controls style={{ height: '35px' }}>
                                            <source src={`${item.audio_path}`} type="audio/mp3" />
                                        </audio>
                                    </Flex>
                            }
                        </Box>
                    )
                })}
                {
                    lastContent != "" &&
                    <Box>
                        <Flex justify={'right'}>
                            <Text
                                p={2}
                                m={1}
                                borderRadius={5}
                                justifySelf={'end'}
                                bg={userChatBgColor}
                                whiteSpace={'pre-wrap'}
                                wordBreak={'break-all'}
                            >
                                {lastContent}
                            </Text>
                        </Flex>
                    </Box>
                }
            </Box>

            {/* Input Area */}
            <Flex justify={'center'}
                pos={'fixed'}
                left={{ base: 3, md: 250 }}
                right={{ base: 3, md: plans.find((item) => { return item.id == me.me?.plan_id })?.plan_name == 'pro' ? 250 : 2 }}
                bottom={(curChatHistory.length == 0 ? 400 : 0)}
            >
                <Textarea
                    mt={5}
                    mb={1.5}
                    // width={"80%"}
                    placeholder={(!loading ? 'メッセージを入力してください' : '文章作成中...')}
                    onKeyDown={(e) => { handleEnter(e) }}
                    // value={userText}
                    value={content}
                    onChange={(e) => { setContent(e.target.value) }}
                    isDisabled={loading}
                    ref={textAreaRef as React.RefObject<HTMLTextAreaElement>}
                >
                </Textarea>
                <IconButton
                    zIndex={1000}
                    pos={'absolute'}
                    right={1}
                    top={8}
                    aria-label="Send"
                    icon={<BiSend />}
                    colorScheme="gray"
                    borderRadius="50%"
                    size="lg"
                    // isDisabled={disableFlag}
                    onClick={(e) => sendMessage()}
                />
            </Flex>

            {/* Icon Area */}
            {/* {curChatHistory.length == 0 &&
                <Box
                    pos={'absolute'}
                    bottom={"100px"}
                    right={{ "base": "30px", md: plans.find((item) => { return item.id == me.me?.plan_id })?.plan_name == 'pro' ? 250 : 0 }}
                    fontSize={"30px"}
                    display={'flex'}
                    gap={"10px"}
                >
                    {plans.find((item) => { return item.id == me.me?.plan_id })?.plan_name == "pro" &&
                        <Flex opacity={saveOpacity}>
                            <Tooltip label="保存">
                                <Link
                                    onMouseEnter={() => setSaveOpacity("100%")}
                                    onMouseLeave={() => setSaveOpacity("20%")}
                                    onClick={() => saveChat()}
                                ><FaSave /></Link>
                            </Tooltip>
                        </Flex>
                    }
                    <Flex opacity={eraserOpacity}>
                        <Tooltip label="クリア">
                            <Link
                                onMouseEnter={() => setEraserOpacity("100%")}
                                onMouseLeave={() => setEraserOpacity("20%")}
                                onClick={() => newChat()}
                            ><FaEraser /></Link>
                        </Tooltip>
                    </Flex>
                </Box>
            } */}

            <Modal isCentered isOpen={isOpen} onClose={onClose}>
                <OverlayOne />
                <ModalContent>
                    <ModalHeader>Warning!</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Text>Do you want to delete "{chatRoom?.title}"?</Text>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={removeChat} mr={2}>Yes</Button>
                        <Button onClick={onClose}>Close</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

        </>
    )
}
