import React, { useEffect, useState } from 'react';
import { Center, Heading } from '@chakra-ui/react';
import {
    Button,
    FormControl,
    Flex,
    Link,
    Stack,
    FormLabel,
    useColorModeValue,
    Box,
    Input,
    HStack,
    Grid,
    GridItem,
} from '@chakra-ui/react';
import { Link as ReactRouterLink } from 'react-router-dom'
import { PinInput, PinInputField, useToast } from '@chakra-ui/react';
import axios from "axios";
import { useSelector } from "react-redux";
// import { RootState } from "../../redux/store";
import { useNavigate } from "react-router-dom";
import { RootState, AppDispatch } from "../../redux/store";
import { handlePostRequest, isEmailValid } from '../../utils/helpers';
import { useDispatch } from 'react-redux';
import { setMe } from '../../redux/meSlice';

export default function EmailVerificationForm(): JSX.Element {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const toast = useToast()
    const me = useSelector((state: RootState) => state.me);
    const [email, setEmail] = useState<string>('');
    const [referLink, setReferLink] = useState<string>()

    const handleEmailChange = (event: any) => {
        setEmail(event.target.value);
    };

    const handleSendClick = async () => {
        if (!isEmailValid(email, toast)) return
        if(referLink == 'forgot-password') {
            const resp = await handlePostRequest(
                `${process.env.REACT_APP_BACKEND_URL}/api/users/forgot-password/`,
                { email: email },
                toast
            )
        }
        else if(referLink == 'register') {
            const resp = await handlePostRequest(
                `${process.env.REACT_APP_BACKEND_URL}/api/users/send-mail-verify/`,
                { email: email },
                toast
            )
            dispatch(setMe(resp.data.data.user_info))
            if (resp.data.navigate) navigate(resp.data.navigate)
        }
    };
    const temp = useColorModeValue('gray.800', 'gray.400')

    useEffect(() => {
        if (referLink == 'register' && me.me) setEmail(me.me?.email)
        try {
            const urlParams = new URLSearchParams(window.location.search);
            const uidb64 = urlParams.get('uidb64');
            const token = urlParams.get('token');
            setReferLink(`${urlParams.get('from')}`)
            if (uidb64 && token)
                handlePostRequest(
                    `${process.env.REACT_APP_BACKEND_URL}/api/users/mail-verify/`,
                    {
                        uidb64: uidb64,
                        token: token
                    },
                    toast
                ).then((resp) => {
                    if (resp.data.navigate) navigate(resp.data.navigate)
                }).catch((error) => {

                })
        } catch (error) {

        }
    }, [])

    return (
        <Flex
            minH={'100vh'}
            align={'center'}
            justify={'center'}
            bg={useColorModeValue('gray.50', 'gray.800')}>
            <Stack
                spacing={4}
                w={'full'}
                maxW={'sm'}
                bg={useColorModeValue('white', 'gray.700')}
                rounded={'xl'}
                boxShadow={'lg'}
                p={6}
                my={1}>
                <Center>
                    <Heading lineHeight={1.1} fontSize={{ base: '2xl', md: '3xl' }}>
                        メールアドレスの認証
                    </Heading>
                </Center>
                {
                    (referLink == 'register' && me.me) &&
                    <Box
                        fontSize={{ base: 'sm', sm: 'md' }}
                        color={temp}>
                        確認リンクが
                        <Link mt={2} href={`mailto:${me.me?.email}`}>
                            {me.me?.email}
                        </Link>
                        に送信されました
                    </Box>
                }
                <Box maxW="md" m="auto" p={4}>
                    <Grid templateColumns="1fr auto">
                        <GridItem colSpan={1}>
                            <FormControl>
                                <Input
                                    type="email"
                                    placeholder="メールアドレスを入力してください。"
                                    value={email}
                                    onChange={handleEmailChange}
                                />
                            </FormControl>
                        </GridItem>
                    </Grid>
                    <Box mt={2}>

                        <Button variant="link" colorScheme="blue">
                            <ReactRouterLink to='/login'>ログイン</ReactRouterLink>
                        </Button>
                        {/* <Link textUnderlineOffset={1} as={ReactRouterLink} to={"/login"}>Login</Link> */}
                    </Box>
                    <Button w={'full'} colorScheme="teal" mt={4} onClick={handleSendClick}>
                        {referLink == "register" ?
                            <>再送信</> : <>送信</>
                        }
                    </Button>
                </Box>
            </Stack>
        </Flex>
    );
}