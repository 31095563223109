import { Button, ButtonGroup, VisuallyHidden } from '@chakra-ui/react'
import { GitHubIcon, GoogleIcon, TwitterIcon } from './ProviderIcons'

const providers = [
  { name: 'Google', icon: <GoogleIcon boxSize="5" /> },
  // { name: 'Twitter', icon: <TwitterIcon boxSize="5" /> },
  // { name: 'GitHub', icon: <GitHubIcon boxSize="5" /> },
]

export default function OAuthButtonGroup () {
  return (
  <ButtonGroup variant="outline" spacing="4" width="full">
    {providers.map(({ name, icon }) => (
      <Button key={name} width="full" onClick={() => {
        const element: HTMLElement | null = document.querySelector(".abcdefgh");
        if (element) {
          console.log(element)
          element.click();
        }
      }}>
        <VisuallyHidden>Sign in with {name}</VisuallyHidden>
        {icon}
      </Button>
    ))}
  </ButtonGroup>
  )
}