import React, { useEffect, useState } from 'react';
import { Center, Heading } from '@chakra-ui/react';
import {
    Button,
    FormControl,
    Flex,
    Link,
    Stack,
    FormLabel,
    useColorModeValue,
    Box,
    Input,
    HStack,
    Grid,
    GridItem,
} from '@chakra-ui/react';
import { Link as ReactRouterLink } from 'react-router-dom'
import { useToast } from '@chakra-ui/react';
import { useSelector } from "react-redux";
// import { RootState } from "../../redux/store";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../redux/store";
import { handlePostRequest, validatePasswords } from '../../utils/helpers';
import { useDispatch } from 'react-redux';
import { setMe } from '../../redux/meSlice';

export default function ResetPassword(): JSX.Element {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const toast = useToast()
    const me = useSelector((state: RootState) => state.me);
    const [password, setPassword] = useState<string>('');
    const [repassword, setRepassword] = useState<string>("");
    const [uidb64, setUidb64] = useState<string>("");
    const [token, setToken] = useState<string>("");
    const handleResetpassword = async () => {
        if(!validatePasswords(password, repassword, toast)) return;
        const resp = await handlePostRequest(
            `${process.env.REACT_APP_BACKEND_URL}/api/users/reset-password/`,
            {
                password: password,
                confirmPassword: repassword,
                uidb64: uidb64,
                token: token,
            },
            toast
        )
        if(resp.data.navigate) navigate(`${resp.data.navigate}?from=register`)
    }
    useEffect(() => {
        try {
            const urlParams = new URLSearchParams(window.location.search);
            setUidb64(`${urlParams.get('uidb64')}`);
            setToken(`${urlParams.get('token')}`);
        } catch (error) {

        }
    }, [])

    return (
        <Flex
            minH={'100vh'}
            align={'center'}
            justify={'center'}
            bg={useColorModeValue('gray.50', 'gray.800')}>
            <Stack
                spacing={4}
                w={'full'}
                maxW={'sm'}
                bg={useColorModeValue('white', 'gray.700')}
                rounded={'xl'}
                boxShadow={'lg'}
                p={6}
                my={1}>
                <Center>
                    <Heading lineHeight={1.1} fontSize={{ base: '2xl', md: '3xl' }}>
                        メールアドレスの認証
                    </Heading>
                </Center>
                <Box maxW="md" m="auto" p={4}>
                    <Grid templateColumns="1fr auto">
                        <GridItem colSpan={1}>
                            <FormControl>
                                <Input
                                    type="text"
                                    placeholder="new password"
                                    onChange={(e)=>{setPassword(e.target.value)}}
                                />
                                <Input
                                    mt={2}
                                    type="text"
                                    placeholder="re-type new password"
                                    onChange={(e)=>{setRepassword(e.target.value)}}
                                />
                            </FormControl>
                        </GridItem>
                    </Grid>
                    <Box mt={2}>

                        <Button variant="link" colorScheme="blue">
                            <ReactRouterLink to='/login'>ログイン</ReactRouterLink>
                        </Button>
                        {/* <Link textUnderlineOffset={1} as={ReactRouterLink} to={"/login"}>Login</Link> */}
                    </Box>
                    <Button w={'full'} colorScheme="teal" mt={4} onClick={()=>{handleResetpassword()}}>
                        <>送信</>
                    </Button>
                </Box>
            </Stack>
        </Flex>
    );
}