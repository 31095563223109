import {
    BrowserRouter,
    Routes,
    Route,
    Navigate,
} from 'react-router-dom';

import Home from "../pages/home"
import SidebarWithHeader from "../layouts/sidebar";
import Login from '../pages/register/Login';
import EmailVerificationForm from '../pages/register/MailVerify';
import { Plan } from '../pages/plan';
import UserProfileEdit from '../pages/user';
import CheckoutForm from "../pages/stripe"
import ConfirmPayment from '../pages/stripe/confirmpayment';
import ConfirmGoogleLogin from '../pages/register/ConfirmGoogleLogin';
import ResetPassword from '../pages/register/ResetPassword';
import { useEffect, useState } from 'react';
import { AudioModelInterface, GPTModelInterface, PlanInterface } from '../utils/interfaces';
import { handleGetRequest, handlePostRequest } from '../utils/helpers';
import { useDispatch } from 'react-redux';
import { useToast } from '@chakra-ui/react';
import { setMe } from '../redux/meSlice';

export const Router = () => {
    const dispatch = useDispatch();
    const toast = useToast();

    return (
        <BrowserRouter>
            <Routes>
                {/* <Route path="/" element={<Home />} /> */}
                <Route path="/" element={<SidebarWithHeader children={<Home />} name="home" />} />
                <Route path="/home" element={<Navigate to={"/"} />} />
                <Route path="/login" element={<Login name="login" />}></Route>
                <Route path="/signup" element={<Login name="signup" />}></Route>
                <Route path="/mail-verify" element={<EmailVerificationForm />}></Route>
                <Route path="/reset-password" element={<ResetPassword />}></Route>
                <Route path="/school" element={<SidebarWithHeader children={"School"} name="school"  />}></Route>
                <Route path="/language" element={<SidebarWithHeader children={"Language"} name="language"  />}></Route>
                <Route path="/certificate" element={<SidebarWithHeader children={"Certificate"} name="certificate"  />}></Route>
                <Route path="/profile" element={<SidebarWithHeader children={<UserProfileEdit />} name="profile"  />}></Route>
                <Route path="/setting" element={<SidebarWithHeader children={"Setting"} name="certificate"  />}></Route>
                <Route path="/plan" element={<SidebarWithHeader children={<Plan />} name="plan"  />}></Route>
                <Route path="/stripe" element={<SidebarWithHeader children={<CheckoutForm />} name="stripe"  />}></Route>
                <Route path="/confirm-payment" element={<SidebarWithHeader children={<ConfirmPayment />} name="confirm-payment"  />}></Route>
                <Route path="/api/auth/callback/google" element={<SidebarWithHeader children={<ConfirmGoogleLogin />} name="confirm-google-login"  />}></Route>
            </Routes>
        </BrowserRouter>
    )
}