import React, { useState } from 'react';
import { GoogleLogin, GoogleLoginResponse, GoogleLoginResponseOffline } from 'react-google-login';

const GoogleLoginButton: React.FC = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleLoginSuccess = (response: GoogleLoginResponse | GoogleLoginResponseOffline) => {
    setIsLoggedIn(true);
    // Handle the successful login response here
    console.log(response);
  };

  const handleLoginFailure = (error: any) => {
    // Handle the failed login error here
    console.error(error);
  };

  return (
    <div>
      {isLoggedIn ? (
        <div>You are logged in with Google!</div>
      ) : (
        <GoogleLogin
          className='abcdefgh'
          clientId={process.env.REACT_APP_CLIENT_ID?process.env.REACT_APP_CLIENT_ID:""}
          buttonText="Login with Google"
          onSuccess={handleLoginSuccess}
          onFailure={handleLoginFailure}
          cookiePolicy={'single_host_origin'}
          uxMode='redirect'
          redirectUri={process.env.REACT_APP_REDIRECT_URI}
        />
      )}
    </div>
  );
};

export default GoogleLoginButton;
