import {
    Box,
    ModalOverlay,
    SimpleGrid,
    useDisclosure,
    useToast,
    Modal,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    Text,
    ModalFooter,
    Button,
} from '@chakra-ui/react'
import { SiHive, SiMarketo, SiMicrosoft } from 'react-icons/si'
import { PricingCard } from './PricingCard'
import { ActionButton } from './ActionButton'
import { useEffect, useState } from 'react'
import { ChevronDownIcon } from '@chakra-ui/icons'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from '../../redux/store'
import { handlePostRequest } from '../../utils/helpers'
import { AddressElement, Elements, PaymentElement, PaymentRequestButtonElement } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from '../stripe'

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`);

export const Plan = () => {
    const navigate = useNavigate();
    const me = useSelector((state: RootState) => state.me.me);
    const plans = useSelector((state: RootState) => state.app.plans);
    const currencies = useSelector((state: RootState) => state.app.currencies)
    const toast = useToast()
    const [loading, setLoading] = useState<boolean>(false);

    const { isOpen, onOpen, onClose } = useDisclosure()

    const OverlayOne = () => (
        <ModalOverlay
            bg='blackAlpha.300'
            backdropFilter='blur(10px) hue-rotate(90deg)'
        />
    )

    const [options, setOptions] = useState<any>(
        {
            layout: 'accordion',
            clientSecret: '',
        }
    );
    const sendPaymentRequest = async () => {
        onOpen()
        setLoading(true)
        const resp = await handlePostRequest(
            `${process.env.REACT_APP_BACKEND_URL}/api/payment/charge/`,
            {},
            toast,
            'default'
        )
        setLoading(false)
        if (resp) {
            setOptions((prev: any) => ({ ...prev, clientSecret: resp.data }))
        }
    }
    return (

        <Box
            as="section"
            // bg={useColorModeValue('gray.50', 'gray.800')}
            py="14"
            px={{ base: '4', md: '8' }}
            mt={"40px"}
        >
            <SimpleGrid
                columns={{ base: 1, lg: 1 }}
                spacing={{ base: '8', lg: '0' }}
                maxW="7xl"
                mx="auto"
                justifyItems="center"
                alignItems="center"
            >

                {/* Free Plan */}
                {
                    me ?
                        <>
                            {plans.find((item) => item.id == me?.plan_id)?.plan_name == "free" &&
                                <PricingCard
                                    data={{
                                        price: `${currencies.find((item) => plans.find((item) => item.plan_name == 'pro')?.currency_id == item?.id)?.symbol}${plans.find((item) => item.plan_name == 'pro')?.plan_price}`,
                                        name: 'プラス',
                                        features: [
                                            `無制限のリクエスト`,
                                            // `1 日あたり${plans.find((item) => item.plan_name == 'pro')?.request_no_per_day}件のリクエスト`,
                                            // 'Lifetime access',
                                            // 'Use on unlimited projects',
                                            // 'Free Updates',
                                        ],
                                    }}
                                    icon={SiMicrosoft}
                                    button={
                                        <ActionButton variant="outline" borderWidth="2px" isDisabled={loading} onClick={() => sendPaymentRequest()}>
                                            今すぐ購入
                                        </ActionButton>
                                    }
                                />
                            }
                        </> :
                        <PricingCard
                            zIndex={1}
                            isPopular
                            // transform={{ lg: 'scale(1.05)' }}
                            data={{
                                price: `${currencies.find((item) => plans.find((item) => item.plan_name == 'free')?.currency_id == item?.id)?.symbol}${plans.find((item) => item.plan_name == 'free')?.plan_price}`,
                                name: '無料',
                                features: [
                                    `1 日あたり${plans.find((item) => item.plan_name == 'free')?.request_no_per_day}件のリクエスト`,
                                    // 'Lifetime access',
                                    // 'Use on unlimited projects',
                                    // 'Use on unlimited projects',
                                    // 'Free Updates',
                                ],
                            }}
                            icon={SiHive}
                            button={<ActionButton onClick={() => navigate("/signup")}>今すぐ購入</ActionButton>}
                        />
                }
            </SimpleGrid>

            <Modal isCentered isOpen={isOpen} onClose={onClose}>
                <OverlayOne />
                <ModalContent>
                    <ModalHeader>Subscription</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {/* <Text>Subscription</Text> */}
                        {
                            options.clientSecret != '' &&
                            <Elements stripe={stripePromise} options={options} >
                                <CheckoutForm />
                            </Elements>
                        }
                    </ModalBody>
                    <ModalFooter>
                        <Button mr={2}>Pay</Button>
                        <Button onClick={onClose}>Close</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Box>
    )
}
