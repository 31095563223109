import React, { ReactNode, useEffect, useState } from 'react';
import {
    IconButton,
    Avatar,
    Box,
    CloseButton,
    Flex,
    HStack,
    VStack,
    Icon,
    useColorModeValue,
    Link,
    Drawer,
    DrawerContent,
    Text,
    useDisclosure,
    BoxProps,
    FlexProps,
    Menu,
    MenuButton,
    MenuGroup,
    Button,
    MenuDivider,
    MenuItem,
    MenuList,
    useToast,
} from '@chakra-ui/react';
import {
    FiHome,
    FiMenu,
    FiBell,
    FiChevronDown,
} from 'react-icons/fi';
import { IconType } from 'react-icons';
import { ReactText } from 'react';
import { FaLanguage, FaSchool, FaCertificate } from 'react-icons/fa';
import { ColorModeSwitcher } from "./ColorModeSwitcher"
import Mode from '../pages/home/mode';
import { useNavigate } from 'react-router-dom'
import axios from 'axios';
import { deleteCookie, getCookie, handleGetRequest, handlePostRequest, removeAllCookies } from '../utils/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { setMe } from '../redux/meSlice';
import { AudioModelInterface, GPTModelInterface, LinkItemInterface, PlanInterface, UserLinkItemInterface } from '../utils/interfaces';
import { setAudioModels, setChatHistorys, setChatRooms, setCurrencies, setGPTModels, setGPTModel, setAudioModel, setPaymentHistorys, setPlans } from '../redux/appSlice';
import { RootState } from '../redux/store';


const LinkItems: LinkItemInterface[] = [
    { name: 'ホーム', icon: FiHome, link: "/" },
    { name: '学校の勉強', icon: FaSchool, link: "/school" },
    { name: '語学', icon: FaLanguage, link: "/language" },
    // { name: 'お気に入り', icon: FiStar },
    { name: '資格', icon: FaCertificate, link: "/certificate" },
];


const UserLinkItems: UserLinkItemInterface[] = [
    { name: "プロフィール", link: "/profile" },
    { name: "設定", link: "/setting" },
    { name: "請求する", link: "/plan" },
]

export default function SidebarWithHeader(
    {
        children,
        name,
    }: {
        children: ReactNode;
        name: string;
    }
) {
    const toast = useToast();
    const dispatch = useDispatch();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [sessionOut, setSessionOut] = useState<boolean>(false);
    const [userName, setUserName] = useState<string>("ユーザー");
    const navigate = useNavigate()

    function signOut() {
        handlePostRequest(
            `${process.env.REACT_APP_BACKEND_URL}/api/users/logout/`,
            { refresh_token: getCookie('refresh_token') },
            toast,
            'default'
        )
        dispatch(setMe(null));
        deleteCookie('refresh_token')
        deleteCookie('access_token')
        navigate("/login")
    }

    const fetchMe = async () => {
        const resp = await handlePostRequest(
            `${process.env.REACT_APP_BACKEND_URL}/api/users/fetch-me/`,
            {},
            toast,
            'default'
        )
        if (resp.data.data)
            dispatch(setMe(resp.data.data.user_info))
    }

    const fetchAudioModels = async () => {
        const resp = await handleGetRequest(
            `${process.env.REACT_APP_BACKEND_URL}/api/ai-chat/audio-models/`,
            toast,
        )
        if (resp.data) {
            dispatch(setAudioModels(resp.data))
            dispatch(setAudioModel(resp.data[0]))
        }
    }

    const fetchGptModels = async () => {
        const resp = await handleGetRequest(
            `${process.env.REACT_APP_BACKEND_URL}/api/ai-chat/gpt-models/`,
            toast,
        )
        if (resp.data) {
            dispatch(setGPTModels(resp.data))
            dispatch(setGPTModel(resp.data[0]))
        }
    }

    const fetchPlans = async () => {
        const resp = await handleGetRequest(
            `${process.env.REACT_APP_BACKEND_URL}/api/ai-chat/plans/`,
            toast,
        )
        if (resp.data) {
            dispatch(setPlans(resp.data))
        }
    }

    const fetchChatRooms = async () => {
        const resp = await handleGetRequest(
            `${process.env.REACT_APP_BACKEND_URL}/api/ai-chat/ai-chat-rooms/`,
            toast,
            'default'
        )
        console.log(resp.data)
        if (resp.data) {
            dispatch(setChatRooms(resp.data))
        }
    }

    const fetchChatHistorys = async () => {
        const resp = await handleGetRequest(
            `${process.env.REACT_APP_BACKEND_URL}/api/ai-chat/ai-chat-history/`,
            toast,
            'default'
        )
        console.log(resp.data)
        if (resp.data) {
            dispatch(setChatHistorys(resp.data))
        }
    }


    const fetchCurrencies = async () => {
        const resp = await handleGetRequest(
            `${process.env.REACT_APP_BACKEND_URL}/api/ai-chat/currencies/`,
            toast,
            'default'
        )
        console.log(resp.data)
        if (resp.data) {
            dispatch(setCurrencies(resp.data))
        }
    }

    const fetchPaymentHistorys = async () => {
        const resp = await handleGetRequest(
            `${process.env.REACT_APP_BACKEND_URL}/api/ai-chat/payment-history/`,
            toast,
            'default'
        )
        console.log(resp.data)
        if (resp.data) {
            dispatch(setPaymentHistorys(resp.data))
        }
    }

    useEffect(() => {
        fetchMe()
        fetchAudioModels()
        fetchGptModels()
        fetchPlans()
        fetchChatRooms()
        fetchChatHistorys()
        fetchPaymentHistorys()
        fetchCurrencies()
    }, [])

    return (
        <Box minH="100vh" bg={useColorModeValue('gray.100', 'gray.900')} overflowX={'hidden'}>
            <SidebarContent
                onClose={() => onClose}
                name={name}
                display={{ base: 'none', md: 'block' }}
                sessionOut={sessionOut}
                userName={userName}
            />
            <Drawer
                autoFocus={false}
                isOpen={isOpen}
                placement="left"
                onClose={onClose}
                returnFocusOnClose={false}
                onOverlayClick={onClose}
                size="full">
                <DrawerContent>
                    <SidebarContent onClose={onClose} name={name} sessionOut={sessionOut} userName={userName} />
                </DrawerContent>
            </Drawer>
            {/* mobilenav */}
            <MobileNav onOpen={onOpen} onClose={onClose} signOut={signOut} name={name} userName={userName} sessionOut={sessionOut} />
            <Box ml={{ base: 0, md: 60 }} p="4">
                {children}
            </Box>
        </Box>
    );
}

interface SidebarProps extends BoxProps {
    onClose: () => void;
    name: string;
    sessionOut: boolean;
    userName: string;
}

const SidebarContent = ({ onClose, name, ...rest }: SidebarProps) => {
    const navigate = useNavigate()
    const screenWidth = window.innerWidth
    const me = useSelector((state: RootState) => state.me.me)
    const plans = useSelector((state: RootState) => state.app.plans)

    useEffect(() => {
        console.log(me)
    }, [me])

    return (
        <Box
            transition="3s ease"
            bg={useColorModeValue('white', 'gray.900')}
            borderRight="1px"
            borderRightColor={useColorModeValue('gray.200', 'gray.700')}
            w={{ base: 'full', md: 60 }}
            pos="fixed"
            h="full"
            {...rest}>
            <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
                <Text fontSize="2xl" fontFamily="monospace" fontWeight="bold">
                    Parakeet
                </Text>
                <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
            </Flex>
            {LinkItems.map((link) => (
                <NavItem key={link.name} icon={link.icon} link={link.link} onClick={onClose}>
                    {link.name}
                </NavItem>
            ))}
            <Flex alignItems={'center'} pos={'absolute'} bottom={screenWidth < 500 ? '120px' : '40px'} left={5}>
                <Menu>
                    <MenuButton
                        py={2}
                        transition="all 0.3s"
                        _focus={{ boxShadow: 'none' }}
                        onClick={() => navigate("/plan")}
                    >
                        <HStack>
                            <VStack
                                // display={{ base: 'none', md: 'flex' }}
                                alignItems="flex-start"
                                spacing="1px"
                                ml="2"
                            >
                                {
                                    me ?
                                        <>
                                            {
                                                plans.find((item) => {return item.id == me?.plan_id})?.plan_name == "free" &&
                                                <Text fontSize="sm">有料会員登録</Text>
                                            }
                                        </> :
                                        <>
                                            <Text fontSize="sm">無料会員登録</Text>
                                        </>
                                }
                                {/* <Text fontSize="xs" color="gray.600">
                                    このサイトについて
                                </Text> */}
                            </VStack>
                        </HStack>
                    </MenuButton>
                </Menu>
            </Flex>

            <Flex alignItems={'center'} pos={'absolute'} bottom={screenWidth < 500 ? 70 : 2} left={5}>
                <Menu>
                    <MenuButton
                        py={2}
                        transition="all 0.3s"
                        _focus={{ boxShadow: 'none' }}
                        onClick={() => navigate("/")}
                    >
                        <HStack>
                            <VStack
                                // display={{ base: 'none', md: 'flex' }}
                                alignItems="flex-start"
                                spacing="1px"
                                ml="2"
                            >
                                <Text fontSize="sm">このサイトについて</Text>
                                {/* <Text fontSize="xs" color="gray.600">
                                    このサイトについて
                                </Text> */}
                            </VStack>
                        </HStack>
                    </MenuButton>
                </Menu>
            </Flex>
        </Box>
    );
};

interface NavItemProps extends FlexProps {
    icon: IconType;
    children: ReactText;
    link: string;
}
const NavItem = ({ icon, children, ...rest }: NavItemProps) => {
    const navigate = useNavigate()

    function handleClick(e: any, link: string) {
        e.preventDefault();
        console.log(link)
        navigate(link)
    }

    return (
        <Link onClick={(e) => { handleClick(e, rest.link) }} style={{ textDecoration: 'none' }} _focus={{ boxShadow: 'none' }}>
            <Flex
                align="center"
                p="4"
                mx="4"
                borderRadius="lg"
                role="group"
                cursor="pointer"
                _hover={{
                    bg: 'cyan.400',
                    color: 'white',
                }}
                {...rest}
            >
                {icon && (
                    <Icon
                        mr="4"
                        fontSize="16"
                        _groupHover={{
                            color: 'white',
                        }}
                        as={icon}
                    />
                )}
                {children}
            </Flex>
        </Link>
    );
};

interface MobileProps extends FlexProps {
    onOpen: () => void;
    onClose: () => void;
    signOut: () => void;
    name: string;
    sessionOut: boolean;
    userName: string;
}
const MobileNav = ({ onOpen, onClose, signOut, name, ...rest }: MobileProps) => {
    const navigate = useNavigate()
    const plans = useSelector((state: RootState) => state.app.plans)
    const me = useSelector((state: RootState) => state.me.me)
    return (
        <>
            {name == "home" &&
                <Box display={{ base: 'none', md: 'flex' }} pos={'absolute'} left={"40%"} top={5} zIndex={11}>
                    <Mode category='chat'></Mode>
                    <Mode category='audio'></Mode>
                </Box>
            }
            <Flex
                ml={{ base: 0, md: 60 }}
                px={{ base: 4, md: 4 }}
                height={"20"}
                alignItems="center"
                bg={useColorModeValue('white', 'gray.900')}
                borderBottomWidth={"1px"}
                // display={name=="home" ? "flex" : "none"}
                borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
                justifyContent={{ base: 'space-between', md: 'flex-end' }}
                pos={'relative'}
                zIndex={10}
                {...rest}
            >
                <IconButton
                    display={{ base: 'flex', md: 'none' }}
                    onClick={onOpen}
                    variant="outline"
                    aria-label="open menu"
                    icon={<FiMenu />}
                />

                <Text
                    display={{ base: 'flex', md: 'none' }}
                    fontSize="2xl"
                    fontFamily="monospace"
                    fontWeight="bold">
                    Parakeet
                </Text>
                <HStack display="flex" spacing={{ base: '0', md: '3' }}>
                    {!me ?
                        <Menu>
                            <MenuButton
                                py={2}
                                transition="all 0.3s"
                                _focus={{ boxShadow: 'none' }}>
                                <HStack>
                                    <VStack
                                        alignItems="flex-start"
                                        spacing="1px"
                                        ml="2"
                                        cursor={'pointer'}
                                    >
                                        <IconButton
                                            size={'lg'}
                                            variant={'ghost'}
                                            aria-label='login'
                                            icon={<img width={'30px'} src="./icons/user.png" alt="login" />}
                                        />
                                    </VStack>
                                </HStack>
                            </MenuButton>
                            <MenuList>
                                <MenuItem onClick={() => { navigate('/login'); onClose() }}>{'ログイン'}</MenuItem>
                            </MenuList>
                        </Menu> :
                        <Menu>
                            <MenuButton
                                py={2}
                                transition="all 0.3s"
                                _focus={{ boxShadow: 'none' }}>
                                <HStack>
                                    <VStack
                                        alignItems="flex-start"
                                        spacing="1px"
                                        ml="2"
                                        cursor={'pointer'}
                                    >
                                        <IconButton
                                            size={'lg'}
                                            variant={'ghost'}
                                            aria-label='login'
                                            icon={<img width={'30px'} src="./icons/user.png" alt="login" />}
                                        />
                                    </VStack>
                                </HStack>
                            </MenuButton>
                            <MenuList>
                                {UserLinkItems.map((item, index) => {
                                    if(!(me.plan_id == plans.find((item) => item.plan_name == 'pro')?.id && item.name == '請求する'))
                                    return (
                                        <MenuItem key={index} onClick={() => { navigate(item.link); onClose() }}>{item.name}</MenuItem>
                                    )
                                })}
                                <MenuDivider />
                                <MenuItem onClick={() => { signOut() }}>サインアウト</MenuItem>
                            </MenuList>
                        </Menu>
                    }

                    <ColorModeSwitcher />
                    <IconButton
                        size="lg"
                        variant="ghost"
                        aria-label="open menu"
                        icon={<FiBell />}
                    />
                </HStack>
            </Flex>
        </>
    );
};