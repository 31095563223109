import { Box, Button, useToast } from "@chakra-ui/react";
import { useStripe } from "@stripe/react-stripe-js";
import { useElements } from "@stripe/react-stripe-js";
import { PaymentElement } from '@stripe/react-stripe-js';
import axios from "axios";
import { Elements } from '@stripe/react-stripe-js';
import { useEffect, useState } from "react";
import { loadStripe } from '@stripe/stripe-js';
import { useNavigate } from "react-router-dom";

const ConfirmPayment = () => {
    const navigate = useNavigate()
    const toast = useToast()
    useEffect(() => {

        // Get the URL search params
        const urlParams = new URLSearchParams(window.location.search);

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
        // Get the payment_intent parameter
        const paymentIntent = urlParams.get("payment_intent");
        console.log("Payment intent:", paymentIntent);

        // Get the payment_intent_client_secret parameter
        const clientSecret = urlParams.get("payment_intent_client_secret");
        console.log("Client secret:", clientSecret);
        axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/api/payment-verify/`,
            {
                email: localStorage.getItem("email"),
                paymentIntent: paymentIntent,
                paymentIntentClientSecret: clientSecret
            },
            {headers: headers}
        ).then((resp) => {
            if(resp.data == "success payment"){
                toast({
                    title: 'プロプランにアップグレード',
                    // description: "We've created your account for you.",
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                    position: 'top-right'
                })
                navigate("/home")  
            }
        }).catch(() => {
            
            toast({
                title: '不明なエラーが発生しました \n 管理者に連絡してください(admin@admin.com)',
                // description: "We've created your account for you.",
                status: 'success',
                duration: 3000,
                isClosable: true,
                position: 'top-right'
            })
        })
    }, [])
    return (
        <>
            Confirm Payment
        </>
    )
}

export default ConfirmPayment;