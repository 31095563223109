import { Provider } from "react-redux"
import {
    ChakraProvider,
} from "@chakra-ui/react"
import theme from "./utils/theme"
import { Router } from "./routes/router"
import store from "./redux/store"
import { useEffect } from "react"

export const App = () => {
    useEffect(() => {
        document.body.style.overflowX = 'hidden';
        document.body.style.setProperty('-webkit-overflow-scrolling', 'touch');
    
        // Clean up the styles when the component unmounts
        return () => {
          document.body.style.overflowX = '';
          document.body.style.removeProperty('-webkit-overflow-scrolling');
        };
    }, [])
    return(
        <Provider store={store}>
            <ChakraProvider theme={theme} >
                <Router />
            </ChakraProvider>
        </Provider>
    )
}
