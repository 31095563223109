import {
    Box,
    Button,
    Checkbox,
    Container,
    Divider,
    FormControl,
    FormLabel,
    Heading,
    HStack,
    Input,
    Stack,
    Text,
    Flex,
    useToast,
    // Link,
} from '@chakra-ui/react'
import { Logo } from './Logo'
import OAuthButtonGroup from './OAuthButtonGroup'
import { PasswordField } from './PasswordField'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { useState, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from "react-redux";
import { setMe } from '../../redux/meSlice'
import GoogleLoginButton from './GoogleLoginButton'
import { handlePostRequest, isEmailValid, setCookie, validatePasswords } from '../../utils/helpers'

export default function Login({name}: {name: string}) {
    
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const toast = useToast()
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [repassword, setRepassword] = useState<string>("");

    function handleEnter(e: any, name?: string) {
        // e.preventDefault()
        if(e.key == "Enter"){
            if(name == "login") submitLogin();
            else submitSignup();
        }
    };

    const submitLogin = async () => {
        const resp = await handlePostRequest(
            `${process.env.REACT_APP_BACKEND_URL}/api/users/login/`, 
            {
                email: email,
                password: password
            },
            toast
        )
        setCookie("access_token", resp.data.data.access_token)
        setCookie("refresh_token", resp.data.data.refresh_token)
        dispatch(setMe(resp.data.data.user_info))
        if(resp.data.navigate) navigate(resp.data.navigate)
    }
  
    const submitSignup = async () => {
        if(!validatePasswords(password, repassword, toast)) return;
        if(!isEmailValid(email, toast)) return;
        const resp = await handlePostRequest(
            `${process.env.REACT_APP_BACKEND_URL}/api/users/register/`,
            {
                email: email,
                password: password
            },
            toast
        )
        dispatch(setMe(resp.data.data.user_info))
        if(resp.data.navigate) navigate(`${resp.data.navigate}?from=register`)
    }
    
    
    return(
    <Container maxW="lg" py={{ base: '12', md: '24' }} px={{ base: '0', sm: '8' }}>
        <Stack spacing="8">
            <Stack spacing="6">
                {/* <Logo /> */}
                <Flex justify={'center'} cursor={'pointer'} onClick={() => {navigate('/')}}>
                    <Text fontSize={30} fontWeight={700} letterSpacing={3}>Parakeet</Text>
                </Flex>
                <Stack spacing={{ base: '2', md: '3' }} textAlign="center">
                    {name == "login"?(
                        <>
                        <Heading size={{ base: 'xs', md: 'sm' }}>あなたのアカウントにログイン</Heading>
                        <HStack spacing="1" justify="center">
                            <Text color="muted">アカウントを持っていませんか? &nbsp; </Text>
                            <Button variant="link" colorScheme="blue">
                                <Link to='/signup'>サインアップ</Link>
                            </Button>
                        </HStack>
                        </>
                    ): (
                        <>
                        <Heading size={{ base: 'xs', md: 'sm' }}>アカウントを作成する</Heading>
                        <HStack spacing="1" justify="center">
                            <Text color="muted">すでにアカウントをお持ちですか？ &nbsp; </Text>
                            <Button variant="link" colorScheme="blue">
                                <Link to='/login'>ログイン</Link>
                            </Button>
                        </HStack>
                        </>
                    )
                    }
                </Stack>
            </Stack>
            <Box
                py={{ base: '0', sm: '8' }}
                px={{ base: '4', sm: '10' }}
                bg={{ base: 'transparent', sm: 'bg-surface' }}
                boxShadow={{ base: 'none', sm: 'md' }}
                borderRadius={{ base: 'none', sm: 'xl' }}
            >
                <Stack spacing="6">
                    <Stack spacing="5">
                        <FormControl>
                            <FormLabel htmlFor="email">メール</FormLabel>
                            <Input id="email" type="email" onChange={(e) => setEmail(e.target.value)} value={email}/>
                        </FormControl>
                        <PasswordField name={name} onEnter={handleEnter} setPassword={(e) => {setPassword(e.target.value)}} setRepassword={(e) => {setRepassword(e.target.value)}}/>
                    </Stack>
                    {name == "login" &&
                        <HStack justify="space-between">
                            <Checkbox defaultChecked><Text fontSize={13}>私を覚えておいてください</Text></Checkbox>
                            <Button variant="link" colorScheme="blue" size="sm" fontSize={12} onClick={() => navigate('/mail-verify?from=forgot-password')}>
                                パスワードをお忘れですか？
                            </Button>
                        </HStack>
                    }
                    <Stack spacing="6">
                        {
                            name=="login"? (
                                    <Button onClick={() => {submitLogin()}}>ログイン</Button>
                                ): (
                                    <Button onClick={() => {submitSignup()}}>サインアップ</Button>
                                )
                        }
                        <HStack>
                            <Divider />
                            <Text fontSize="sm" whiteSpace="nowrap" color="muted">
                                または
                            </Text>
                            <Divider />
                        </HStack>
                        <OAuthButtonGroup />
                        <Box h={0} pos={'absolute'} top={-500000}>
                            <GoogleLoginButton />
                        </Box>
                    </Stack>
                </Stack>
            </Box>
        </Stack>
    </Container>
    )
}