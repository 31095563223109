import { configureStore } from '@reduxjs/toolkit';
import meReducer from './meSlice';
import appReducer from './appSlice';

const store = configureStore({
    reducer: {
        me: meReducer,
        app: appReducer
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
