// src/features/appSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AudioModelInterface, GPTModelInterface, AIChatRoomInterface, AIChatHistoryInterface, CurrencyInterface, PlanInterface, PaymentHistoryInterface } from '../utils/interfaces';

interface AppState {
    audioModels: AudioModelInterface[];
    gptModels: GPTModelInterface[];
    chatRooms: AIChatRoomInterface[];
    chatHistorys: AIChatHistoryInterface[];
    currencies: CurrencyInterface[];
    plans: PlanInterface[];
    paymentHistorys: PaymentHistoryInterface[];
    audioModel: AudioModelInterface | null;
    gptModel: GPTModelInterface | null;
    chatRoom: AIChatRoomInterface | null;
}

const initialState: AppState = {
    audioModels: [],
    gptModels: [],
    chatRooms: [],
    chatHistorys: [],
    currencies: [],
    plans: [],
    paymentHistorys: [],
    audioModel: null,
    gptModel: null,
    chatRoom: null,
};

const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setAudioModels: (state, action: PayloadAction<AudioModelInterface[]>) => {
            state.audioModels = action.payload;
        },
        setGPTModels: (state, action: PayloadAction<GPTModelInterface[]>) => {
            state.gptModels = action.payload;
        },
        setChatRooms: (state, action: PayloadAction<AIChatRoomInterface[]>) => {
            state.chatRooms = action.payload;
        },
        setChatRoom: (state, action: PayloadAction<AIChatRoomInterface | null>) => {
            state.chatRoom = action.payload;
        },
        setChatHistorys: (state, action: PayloadAction<AIChatHistoryInterface[]>) => {
            state.chatHistorys = action.payload;
        },
        setCurrencies: (state, action: PayloadAction<CurrencyInterface[]>) => {
            state.currencies = action.payload;
        },
        setPlans: (state, action: PayloadAction<PlanInterface[]>) => {
            state.plans = action.payload;
        },
        setPaymentHistorys: (state, action: PayloadAction<PaymentHistoryInterface[]>) => {
            state.paymentHistorys = action.payload;
        },
        setAudioModel: (state, action: PayloadAction<AudioModelInterface | null>) => {
            state.audioModel = action.payload;
        },
        setGPTModel: (state, action: PayloadAction<GPTModelInterface | null>) => {
            state.gptModel = action.payload;
        },
    },
});

export const {
    setAudioModels,
    setGPTModels,
    setChatRooms,
    setChatHistorys,
    setCurrencies,
    setPlans,
    setPaymentHistorys,
    setAudioModel,
    setGPTModel,
    setChatRoom,
} = appSlice.actions;

export default appSlice.reducer;
