import {
    Menu,
    MenuButton,
    MenuList,
    Button,
    MenuGroup,
    MenuItem,
    MenuDivider,
    Text,
    Box,
} from "@chakra-ui/react";
import { useColorModeValue } from "@chakra-ui/react";
import { useDispatch } from "react-redux";
// import { setAudioMode } from "../../redux/audioModeSlice";
// import { setChatMode } from "../../redux/chatModeSlice";
// import { setModeDisable } from "../../redux/modeDisableSlice";
import styles from '../../assets/custom.module.css'
// import { RootState } from "../../redux/store";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { setGPTModel } from "../../redux/appSlice";
import { setAudioModel } from "../../redux/appSlice";


interface ItemList {
    audio: string[],
    chat: string[],
}

const itemList: ItemList = {
    audio: [
        "Nanami",
        "Aoi",
        "Daichi",
        "Keita",
        "Mayu",
        "Naoki",
        "Shiori"
    ],
    chat: [
        "gpt-3.5-turbo",
        // "gpt-3.5-turbo-0301",
        // "text-davinci-003",
        // "text-davinci-002",
        // "code-davinci-002",
        // "gpt-4",
        // "gpt-4-0314",
        // "gpt-4-32k",
        // "gpt-4-32k-0314"
    ],
}

const Mode = ({category}: {category: string}) => {
    // const audioModel = useSelector((state: RootState) => state.audioMode.value)
    // const chatModel = useSelector((state: RootState) => state.chatMode.value)
    // const modeDisable = useSelector((state: RootState) => state.modeDisable.value)
    const categoryBtnBg = useColorModeValue("gray.500", "gray.500");
    const dispatch = useDispatch()

    const setMode = (category: string, item: string) => {
        // if (category == 'audio') dispatch(setAudioMode(item));
        // if (category == 'chat') dispatch(setChatMode(item));
    }

    const audioModels = useSelector((state: RootState) => state.app.audioModels);
    const gptModels = useSelector((state: RootState) => state.app.gptModels);
    const audioModel = useSelector((state: RootState) => state.app.audioModel);
    const gptModel = useSelector((state: RootState) => state.app.gptModel);

    return(
    <Box mr={2}>
        <Menu>
            <MenuButton as={Button} bg={categoryBtnBg} fontSize={12} mr={2} className={category=="audio" ? styles.mode: "none"}>
                {
                    category == 'chat' &&
                        <>GPTモデル</>
                }
                {
                    category == 'audio' &&
                        <>スピーチモデル</>
                }
            </MenuButton>
            <MenuList>
                {/* <MenuGroup title='Profile'>
                <MenuItem>My Account</MenuItem>
                <MenuItem>Payments </MenuItem>
                </MenuGroup>
                <MenuDivider /> */}
                {/* {
                    itemList[category as keyof ItemList].map((item, i) => {
                        return <MenuItem key={i} onClick={() => {setMode(category, item)}}>{item}</MenuItem>
                    })
                } */}
                {category == 'audio'?
                    audioModels.map((item, idx) => {
                        return <MenuItem key={idx} onClick={() => {dispatch(setAudioModel(item))}}>{item.audio_model_name}</MenuItem>
                    }):
                    gptModels.map((item, idx) => {
                        return <MenuItem key={idx} onClick={() => {dispatch(setGPTModel(item))}}>{item.gpt_model_name}</MenuItem>
                    })
                }
            </MenuList>
            {category == "audio"?
                <span className={category=="audio" ? styles.mode: "none"}>{audioModel?.audio_model_name}</span>:
                <span>{gptModel?.gpt_model_name}</span>
            }
        </Menu>
    </Box>
    )
}

export default Mode;