import { Box, Button, useToast } from "@chakra-ui/react";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const ConfirmGoogleLogin = () => {
    const navigate = useNavigate()
    const toast = useToast()
    useEffect(() => {
        const url = window.location.href;
        const params = new URLSearchParams(url.split('#')[1]);
    
        const scope = params.get('scope');
        const idToken = params.get('id_token');
        const loginHint = params.get('login_hint');
        const clientId = params.get('client_id');
    
        // Use the extracted parameters as needed
        console.log("scope", scope, "--------------------");
        console.log("idToken", idToken, "--------------------");
        console.log("loginHint", loginHint, "--------------------");
        console.log("clientId", clientId, "--------------------");

        axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/api/google-login/`,
            {
                idToken: idToken
            }
        ).then((resp) => {
            if(resp.data == "registered"){
                toast({
                    title: '登録成功',
                    // description: "We've created your account for you.",
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                    position: 'top-right'
                })
                navigate("/login")
            }else {
                toast({
                    title: 'ログイン成功',
                    // description: "We've created your account for you.",
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                    position: 'top-right'
                })
                localStorage.setItem("token", resp.data.token);
                localStorage.setItem("email", resp.data.email);
                navigate("/home")
            }
        }).catch(() => {
            toast({
                title: '不明なエラーが発生しました \n 管理者に連絡してください(admin@admin.com)',
                // description: "We've created your account for you.",
                status: 'success',
                duration: 3000,
                isClosable: true,
                position: 'top-right'
            })
            navigate("/login")  
        })
    }, [])
    return (
        <>
            Confirm Google Login
        </>
    )
}

export default ConfirmGoogleLogin;
