// Function to set a cookie
import { AxiosResponse, AxiosError } from 'axios';
import axios from 'axios';

export const setCookie = (name: string, value: string, daysToExpire: number = 2) => {
    const date = new Date();
    date.setTime(date.getTime() + (daysToExpire * 24 * 60 * 60 * 1000));
    const expires = "expires=" + date.toUTCString();
    document.cookie = name + "=" + value + ";" + expires + ";path=/";
}

// Function to get a cookie by name
export const getCookie = (name: string) => {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.startsWith(name + '=')) {
            return cookie.substring(name.length + 1);
        }
    }
    return "";
}
// Function to delete a cookie by name
export const deleteCookie = (name: string) => {
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;";
}
export const removeAllCookies = () => {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        const cookieName = cookie.split('=')[0];
        // Set the cookie's expiration date to a past date (Thu, 01 Jan 1970 00:00:00 UTC)
        document.cookie = cookieName + "=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;";
    }
}

export const getHeaders = () => {
    // console.log(`Token ${getCookie('token')}`)
    return {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getCookie('access_token')}`
    }
}
export const handlePostRequest = async (
    endpoint: string,
    data: object,
    toast: any,
    headers: 'default' | 'null' | Record<string, any> = 'null',
    navigate: any = null
): Promise<AxiosResponse | any> => {
    let config: Record<string, any> = {
        method: 'post',
        url: endpoint,
        data: data,
    };
    switch (headers) {
        case 'default':
            config.headers = getHeaders()
            break
        case 'null':
            break
        default:
            config.headers = headers;
    }
    try {
        const response: AxiosResponse = await axios(config);
        try {
            if (response.data.message)
            {
                await toast({
                    title: response.data.message,
                    // description: "We've created your account for you.",
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                    position: 'top-right'
                })
            }
        } catch (error) {
        }
        return response
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const axiosError: any = error;
            try {
                if (axiosError.response.data.message){
                    await toast({
                        title: axiosError.response.data.message,
                        // description: "We've created your account for you.",
                        status: 'error',
                        duration: 3000,
                        isClosable: true,
                        position: 'top-right'
                    })
                    if(axiosError.response.data.message == 'daily limited') {
                        navigate('/plan')
                    }
                }
            } catch (error) {
                await toast({
                    title: 'unknown error',
                    // description: "We've created your account for you.",
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                    position: 'top-right'
                })
            }
        } else {
            await toast({
                title: 'network error',
                // description: "We've created your account for you.",
                status: 'error',
                duration: 3000,
                isClosable: true,
                position: 'top-right'
            })
        }
        return
    }
};

export const handleGetRequest = async (
    endpoint: string,
    toast: any,
    headers: 'default' | 'null' | Record<string, any> = 'null'
): Promise<AxiosResponse | any> => {
    let config: Record<string, any> = {
        method: 'get', // Change the method to 'get' for a GET request
        url: endpoint,
    };

    switch (headers) {
        case 'default':
            config.headers = getHeaders();
            break;
        case 'null':
            break;
        default:
            config.headers = headers;
    }

    try {
        const response: AxiosResponse = await axios(config);
        return response;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const axiosError: any = error;
        } else {
        }
        return;
    }
};

export const handleDeleteRequest = async (
    endpoint: string,
    toast: any,
    headers: 'default' | 'null' | Record<string, any> = 'null'
): Promise<AxiosResponse | any> => {
    let config: Record<string, any> = {
        method: 'delete', // Change the method to 'get' for a GET request
        url: endpoint,
    };

    switch (headers) {
        case 'default':
            config.headers = getHeaders();
            break;
        case 'null':
            break;
        default:
            config.headers = headers;
    }

    try {
        const response: AxiosResponse = await axios(config);
        return response;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const axiosError: any = error;
        } else {
        }
        return;
    }
};

export const isEmailValid = (email: string, toast: any) => {
    // Regular expression pattern for email validation
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,10}$/;
    if (emailPattern.test(email)) {
        return true
    } else {
        toast({
            title: 'Invalid email.',
            // description: "We've created your account for you.",
            status: 'error',
            duration: 3000,
            isClosable: true,
            position: 'top-right'
        })
        return false
    }
}

export const validatePasswords = (password: string, confirmPassword: string, toast: (options: any) => void) => {
    // Password validation criteria
    const lengthRegex = /.{8,}/;
    const uppercaseRegex = /[A-Z]/;
    const lowercaseRegex = /[a-z]/;
    const digitRegex = /\d/;
    const specialCharRegex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/;

    // Check each criterion for the password
    const isPasswordValid =
        lengthRegex.test(password) &&
        uppercaseRegex.test(password) &&
        lowercaseRegex.test(password) &&
        digitRegex.test(password) &&
        specialCharRegex.test(password);
    if (!isPasswordValid) {
        toast({
            title: 'Invalid password. Password must meet the criteria.',
            // description: "We've created your account for you.",
            status: 'error',
            duration: 3000,
            isClosable: true,
            position: 'top-right'
        })
        return false
    }

    // Check if the passwords match
    const doPasswordsMatch = password === confirmPassword;
    if (!doPasswordsMatch) {
        toast({
            title: 'Passwords do not match.',
            // description: "We've created your account for you.",
            status: 'error',
            duration: 3000,
            isClosable: true,
            position: 'top-right'
        })
        return false
    }

    return true
}
