// userSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MeInterface } from '../utils/interfaces';

interface MeState {
    me: MeInterface | null;
}

const initialState: MeState = {
    me: null,
};

const meSlice = createSlice({
    name: 'me',
    initialState,
    reducers: {
        setMe: (state, action: PayloadAction<MeInterface | null>) => {
            state.me = action.payload;
        },
    },
});

export const { setMe } = meSlice.actions;
export default meSlice.reducer;
